<div id="serviceStatusPage" class="noMargins">
    <page-title>{{('Service status'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Refresh interval'|translate}}</span>
            <select required [(ngModel)]="query.RefreshInterval" name="query.RefreshInterval" (ngModelChange)="updateRefreshInterval()">
                <option value="15">{{'15 seconds'|translate}}</option>
                <option value="60">{{'1 minute'|translate}}</option>
                <option value="300">{{'5 minutes'|translate}}</option>
                <option value="600">{{'10 minutes'|translate}}</option>
            </select>
        </label>

        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
    </form>
    <div class="form contentMargins">
        <div class="services" *ngIf="serviceMonitorStatus != null">
            <dashboard-service name="{{'Service monitoring'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.ServiceMonitoringStatusHistory"></dashboard-service>
            <dashboard-service name="{{'Data collector'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.DataCollectorStatusHistory"></dashboard-service>
            <dashboard-service name="{{'Communicator'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.CommunicatorStatusHistory"></dashboard-service>
            <dashboard-service name="{{'Portal'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.ClientPortalsWebServiceStatusHistory"></dashboard-service>
            <dashboard-service name="{{'Mobile API'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.ClientWebServiceStatusHistory"></dashboard-service>
            <dashboard-service name="{{'Admin'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.ConfigurationWebServiceStatusHistory"></dashboard-service>
            <dashboard-service name="{{'DDMS'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.DeicingManagementWebServiceStatusHistory"></dashboard-service>
            <dashboard-service name="{{'Service provider API'|translate}}" [serviceStatusHistory]="serviceMonitorStatus?.ServiceProviderWebServiceStatusHistory"></dashboard-service>
        </div>

        <h3>{{'APIs'|translate}}</h3>
        <table class="table">
            <tr>
                <th>{{'Name'|translate}}</th>
                <th>{{'Status'|translate}}</th>
                <th>{{'Response time (ms)'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Mobile HOTs'|translate}}</td>
                <td [class.critical]="serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileHotOnline != true">{{serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileHotOnline ? 'Online' : 'Offline'}}</td>
                <td [class.warning]="serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileHotResponseTimeMs > 1000" [class.critical]="serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileHotResponseTimeMs > 5000">{{serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileHotResponseTimeMs}}</td>
            </tr>
            <tr>
                <td>{{'Mobile configuration'|translate}}</td>
                <td [class.critical]="serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileConfigurationOnline != true">{{serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileConfigurationOnline ? 'Online' : 'Offline'}}</td>
                <td [class.warning]="serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileConfigurationResponseTimeMs > 1000" [class.critical]="serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileConfigurationResponseTimeMs > 5000">{{serviceMonitorStatus?.ClientWebServiceStatusHistory?.at(-1)?.MobileConfigurationResponseTimeMs}}</td>
            </tr>
            <tr>
                <td>{{'Portal HOTs'|translate}}</td>
                <td [class.critical]="serviceMonitorStatus?.ClientPortalsWebServiceStatusHistory?.at(-1)?.ClientPortalHotOnline != true">{{serviceMonitorStatus?.ClientPortalsWebServiceStatusHistory?.at(-1)?.ClientPortalHotOnline ? 'Online' : 'Offline'}}</td>
                <td [class.warning]="serviceMonitorStatus?.ClientPortalsWebServiceStatusHistory?.at(-1)?.ClientPortalHotResponseTimeMs > 1000" [class.critical]="serviceMonitorStatus?.ClientPortalsWebServiceStatusHistory?.at(-1)?.ClientPortalHotResponseTimeMs > 5000">{{serviceMonitorStatus?.ClientPortalsWebServiceStatusHistory?.at(-1)?.ClientPortalHotResponseTimeMs}}</td>
            </tr>
            <tr>
                <td>{{'ACARS HOTs'|translate}}</td>
                <td [class.critical]="serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.AcarsHotOnline != true">{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.AcarsHotOnline ? 'Online' : 'Offline'}}</td>
                <td [class.warning]="serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.AcarsHotResponseTimeMs > 1000" [class.critical]="serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.AcarsHotResponseTimeMs > 5000">{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.AcarsHotResponseTimeMs}}</td>
            </tr>
        </table>
        
        <h3>{{'Processing queues'|translate}}</h3>
        <table class="table queues">
            <tr>
                <th>{{'Name'|translate}}</th>
                <th style="width: 70px;">{{'Queue depth'|translate}}</th>
                <th style="width: 170px;">{{'History'|translate}}</th>
                <th style="min-width: 400px;">{{'Status'|translate}}</th>
            </tr>
            <tr>
                <td>{{'METAR'|translate}}</td>
                <td>{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.MetarReadingUpdateQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.DataCollectorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.MetarReadingUpdateQueueDepth === null ? 100 : (Math.max(5, Math.min(h.MetarReadingUpdateQueueDepth, 100)))"
                                  [style.background-color]="h.MetarReadingUpdateQueueDepth === null ? '#555555' : (h.MetarReadingUpdateQueueDepth > 50 ? '#ff0000' : (h.MetarReadingUpdateQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>
                    <label>
                        <span class="caption">{{'METAR update duration (ms)'|translate}}</span>
                        <span>{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.MetarReadingUpdateTickDuration}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'Last METAR update'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastMetarReadingUpdate">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastMetarReadingUpdate|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <td>{{'ACARS'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.ACARSMessageQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.ACARSMessageQueueDepth === null ? 100 : (Math.max(5, Math.min(h.ACARSMessageQueueDepth, 100)))"
                                  [style.background-color]="h.ACARSMessageQueueDepth === null ? '#555555' : (h.ACARSMessageQueueDepth > 50 ? '#ff0000' : (h.ACARSMessageQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td rowspan="3">
                    <table class="table" *ngIf="Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.IBMMQServerConnections ?? {}).length > 0">
                        <tr>
                            <th>{{'Name'|translate}}</th>
                            <th style="min-width: 350px;">{{'Queue'|translate}}</th>
                            <th>{{'Status'|translate}}</th>
                            <th>{{'Since'|translate}}</th>
                            <th>{{'Last message'|translate}}</th>
                        </tr>
                        <tr *ngFor="let connection of Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.IBMMQServerConnections)">
                            <td>{{connection.ConfigurationName}}</td>
                            <td>{{connection.ConnectionString}}</td>
                            <td>{{connection.Status}}</td>
                            <td>{{connection.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                            <td>{{connection.LastMessageDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                        </tr>
                    </table>
                    <div class="critical" *ngIf="!(Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.IBMMQServerConnections ?? {}).length > 0)">{{'No connections'}}</div>
                </td>
            </tr>
            <tr>
                <td>{{'XML'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.XMLMessageQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.XMLMessageQueueDepth === null ? 100 : (Math.max(5, Math.min(h.XMLMessageQueueDepth, 100)))"
                                  [style.background-color]="h.XMLMessageQueueDepth === null ? '#555555' : (h.XMLMessageQueueDepth > 50 ? '#ff0000' : (h.XMLMessageQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
            </tr>
            <tr>
                <td>{{'AIDX'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.AIDXMessageQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.AIDXMessageQueueDepth === null ? 100 : (Math.max(5, Math.min(h.AIDXMessageQueueDepth, 100)))"
                                  [style.background-color]="h.AIDXMessageQueueDepth === null ? '#555555' : (h.AIDXMessageQueueDepth > 50 ? '#ff0000' : (h.AIDXMessageQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
            </tr>
            <tr>
                <td>{{'FlightAware Firehose'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FlightAwareFirehoseQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.FlightAwareFirehoseQueueDepth === null ? 100 : (Math.max(5, Math.min(h.FlightAwareFirehoseQueueDepth, 100)))"
                                  [style.background-color]="h.FlightAwareFirehoseQueueDepth === null ? '#555555' : (h.FlightAwareFirehoseQueueDepth > 50 ? '#ff0000' : (h.FlightAwareFirehoseQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <!--<td>
                    <span *ngIf="config?.FlightAwareFirehoseHostname">{{config.FlightAwareFirehoseUsername}}&#64;{{config.FlightAwareFirehoseHostname}}:{{config.FlightAwareFirehosePort}}</span>
                </td>-->
            </tr>
            <tr>
                <td>{{'Email'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.EmailQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.EmailQueueDepth === null ? 100 : (Math.max(5, Math.min(h.EmailQueueDepth, 100)))"
                                  [style.background-color]="h.EmailQueueDepth === null ? '#555555' : (h.EmailQueueDepth > 50 ? '#ff0000' : (h.EmailQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>
                    <table class="table" *ngIf="Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.IMAPServerConnections ?? {}).length > 0">
                        <tr>
                            <th>{{'Name'|translate}}</th>
                            <th style="min-width: 350px;">{{'Mailbox'|translate}}</th>
                            <th>{{'Status'|translate}}</th>
                            <th>{{'Since'|translate}}</th>
                            <th>{{'Last message'|translate}}</th>
                        </tr>
                        <tr *ngFor="let connection of Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.IMAPServerConnections)">
                            <td>{{connection.ConfigurationName}}</td>
                            <td>{{connection.ConnectionString}}</td>
                            <td>{{connection.Status}}</td>
                            <td>{{connection.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                            <td>{{connection.LastMessageDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                        </tr>
                    </table>
                    <div class="critical" *ngIf="!(Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.IMAPServerConnections ?? {}).length > 0)">{{'No connections'}}</div>
                </td>
            </tr>
            <tr>
                <td>{{'SQS'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SQSQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.SQSQueueDepth === null ? 100 : (Math.max(5, Math.min(h.SQSQueueDepth, 100)))"
                                  [style.background-color]="h.SQSQueueDepth === null ? '#555555' : (h.SQSQueueDepth > 50 ? '#ff0000' : (h.SQSQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>
                    <table class="table" *ngIf="Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SQSQueueConnections ?? {}).length > 0">
                        <tr>
                            <th>{{'Name'|translate}}</th>
                            <th style="min-width: 350px;">{{'Queue'|translate}}</th>
                            <th>{{'Status'|translate}}</th>
                            <th>{{'Since'|translate}}</th>
                            <th>{{'Last message'|translate}}</th>
                        </tr>
                        <tr *ngFor="let connection of Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SQSQueueConnections)">
                            <td>{{connection.ConfigurationName}}</td>
                            <td>{{connection.ConnectionString}}</td>
                            <td>{{connection.Status}}</td>
                            <td>{{connection.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                            <td>{{connection.LastMessageDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                        </tr>
                    </table>
                    <div class="warning" *ngIf="!(Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SQSQueueConnections ?? {}).length > 0)">{{'No connections'}}</div>
                </td>
            </tr>
            <tr>
                <td>{{'SFTP'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SFTPServerQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.SFTPServerQueueDepth === null ? 100 : (Math.max(5, Math.min(h.SFTPServerQueueDepth, 100)))"
                                  [style.background-color]="h.SFTPServerQueueDepth === null ? '#555555' : (h.SFTPServerQueueDepth > 50 ? '#ff0000' : (h.SFTPServerQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>
                    <table class="table" *ngIf="Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SFTPServerConnections ?? {}).length">
                        <tr>
                            <th>{{'Name'|translate}}</th>
                            <th style="min-width: 350px;">{{'Queue'|translate}}</th>
                            <th>{{'Status'|translate}}</th>
                            <th>{{'Since'|translate}}</th>
                            <th>{{'Last message'|translate}}</th>
                        </tr>
                        <tr *ngFor="let connection of Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SFTPServerConnections)">
                            <td>{{connection.ConfigurationName}}</td>
                            <td>{{connection.ConnectionString}}</td>
                            <td>{{connection.Status}}</td>
                            <td>{{connection.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                            <td>{{connection.LastMessageDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                        </tr>
                    </table>
                    <div class="warning" *ngIf="!(Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.SFTPServerConnections ?? {}).length > 0)">{{'No connections'}}</div>
                </td>
            </tr>
            <tr>
                <td>{{'JMS'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.JMSQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.JMSQueueDepth === null ? 100 : (Math.max(5, Math.min(h.JMSQueueDepth, 100)))"
                                  [style.background-color]="h.JMSQueueDepth === null ? '#555555' : (h.JMSQueueDepth > 50 ? '#ff0000' : (h.JMSQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>
                    <table class="table" *ngIf="Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.JMSServerConnections ?? {}).length > 0">
                        <tr>
                            <th>{{'Name'|translate}}</th>
                            <th style="min-width: 350px;">{{'Queue'|translate}}</th>
                            <th>{{'Status'|translate}}</th>
                            <th>{{'Since'|translate}}</th>
                            <th>{{'Last message'|translate}}</th>
                        </tr>
                        <tr *ngFor="let connection of Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.JMSServerConnections)">
                            <td>{{connection.ConfigurationName}}</td>
                            <td>{{connection.ConnectionString}}</td>
                            <td>{{connection.Status}}</td>
                            <td>{{connection.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                            <td>{{connection.LastMessageDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                        </tr>
                    </table>
                    <div class="warning" *ngIf="!(Object.values(serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.JMSServerConnections ?? {}).length > 0)">{{'No connections'}}</div>
                </td>
            </tr>
            <tr>
                <td>{{'Barrel icing warnings'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.BarrelIcingWarningTickQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.BarrelIcingWarningTickQueueDepth === null ? 100 : (Math.max(5, Math.min(h.BarrelIcingWarningTickQueueDepth, 100)))"
                                  [style.background-color]="h.BarrelIcingWarningTickQueueDepth === null ? '#555555' : (h.BarrelIcingWarningTickQueueDepth > 50 ? '#ff0000' : (h.BarrelIcingWarningTickQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.BarrelIcingWarningTickQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'Active frost warnings'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.ActiveFrostWarningTickQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.ActiveFrostWarningTickQueueDepth === null ? 100 : (Math.max(5, Math.min(h.ActiveFrostWarningTickQueueDepth, 100)))"
                                  [style.background-color]="h.ActiveFrostWarningTickQueueDepth === null ? '#555555' : (h.ActiveFrostWarningTickQueueDepth > 50 ? '#ff0000' : (h.ActiveFrostWarningTickQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.ActiveFrostWarningTickQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'Engine cover alerts'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.EngineCoverAlertTickQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.EngineCoverAlertTickQueueDepth === null ? 100 : (Math.max(5, Math.min(h.EngineCoverAlertTickQueueDepth, 100)))"
                                  [style.background-color]="h.EngineCoverAlertTickQueueDepth === null ? '#555555' : (h.EngineCoverAlertTickQueueDepth > 50 ? '#ff0000' : (h.EngineCoverAlertTickQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.EngineCoverAlertTickQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'Heat flaps alerts AIDX events'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.HeatFlapsAlertQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.HeatFlapsAlertQueueDepth === null ? 100 : (Math.max(5, Math.min(h.HeatFlapsAlertQueueDepth, 100)))"
                                  [style.background-color]="h.HeatFlapsAlertQueueDepth === null ? '#555555' : (h.HeatFlapsAlertQueueDepth > 50 ? '#ff0000' : (h.HeatFlapsAlertQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.HeatFlapsAlertQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'Heat flaps alerts ticks'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.HeatFlapsAlertTickQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.HeatFlapsAlertTickQueueDepth === null ? 100 : (Math.max(5, Math.min(h.HeatFlapsAlertTickQueueDepth, 100)))"
                                  [style.background-color]="h.HeatFlapsAlertTickQueueDepth === null ? '#555555' : (h.HeatFlapsAlertTickQueueDepth > 50 ? '#ff0000' : (h.HeatFlapsAlertTickQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.HeatFlapsAlertTickQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'Flap contamination alerts AIDX events'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FlapContaminationAlertQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.FlapContaminationAlertQueueDepth === null ? 100 : (Math.max(5, Math.min(h.FlapContaminationAlertQueueDepth, 100)))"
                                  [style.background-color]="h.FlapContaminationAlertQueueDepth === null ? '#555555' : (h.FlapContaminationAlertQueueDepth > 50 ? '#ff0000' : (h.FlapContaminationAlertQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FlapContaminationAlertQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'Flap contamination alerts ticks'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FlapContaminationAlertTickQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.FlapContaminationAlertTickQueueDepth === null ? 100 : (Math.max(5, Math.min(h.FlapContaminationAlertTickQueueDepth, 100)))"
                                  [style.background-color]="h.FlapContaminationAlertTickQueueDepth === null ? '#555555' : (h.FlapContaminationAlertTickQueueDepth > 50 ? '#ff0000' : (h.FlapContaminationAlertTickQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FlapContaminationAlertTickQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'FZFG alerts OOOIs'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FreezingFogAlertQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.FreezingFogAlertQueueDepth === null ? 100 : (Math.max(5, Math.min(h.FreezingFogAlertQueueDepth, 100)))"
                                  [style.background-color]="h.FreezingFogAlertQueueDepth === null ? '#555555' : (h.FreezingFogAlertQueueDepth > 50 ? '#ff0000' : (h.FreezingFogAlertQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FreezingFogAlertQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'FZFG alerts ticks'|translate}}</td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FreezingFogAlertTickQueueDepth}}</td>
                <td>
                    <span class="historyGraph">
                        <span *ngFor="let h of serviceMonitorStatus?.CommunicatorStatusHistory?.slice(-15); let index = index"
                              class="column"
                              [style.border-left]="(index > 0 ? '1px solid #cccccc' : '')">
                            <span [style.height.%]="h.FreezingFogAlertTickQueueDepth === null ? 100 : (Math.max(5, Math.min(h.FreezingFogAlertTickQueueDepth, 100)))"
                                  [style.background-color]="h.FreezingFogAlertTickQueueDepth === null ? '#555555' : (h.FreezingFogAlertTickQueueDepth > 50 ? '#ff0000' : (h.FreezingFogAlertTickQueueDepth > 20 ? '#ffa500' : '#00ff00'))"></span>
                        </span>
                    </span>
                </td>
                <td>{{serviceMonitorStatus?.CommunicatorStatusHistory?.at(-1)?.FreezingFogAlertTickQueueStatus}}</td>
            </tr>
            <tr>
                <td>{{'SureForecast AllClear'|translate}}</td>
                <td></td>
                <td></td>
                <td>
                    <label>
                        <span class="caption">{{'SureForecast AllClear valid from'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastSureForecastAllClearValidFrom">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastSureForecastAllClearValidFrom|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'SureForecast AllClear error'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastSureForecastAllClearValidFrom">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastSureForecastAllClearValidFrom|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <td>{{'NOAA RAP'|translate}}</td>
                <td></td>
                <td></td>
                <td>
                    <label>
                        <span class="caption">{{'Last NOAA RAP report update'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastNoaaRapReportUpdate">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastNoaaRapReportUpdate|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'Last NOAA RAP report date'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastNoaaRapReportLastReportDate">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastNoaaRapReportLastReportDate|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <td>{{'Open-Meteo'|translate}}</td>
                <td></td>
                <td></td>
                <td>
                    <label>
                        <span class="caption">{{'Last Open-Meteo forecast update'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastOpenMeteoForecastUpdate">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastOpenMeteoForecastUpdate|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'Last Open-Meteo forecast date'|translate}}</span>
                        <span *ngIf="serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastOpenMeteoForecastLastReportDate">{{serviceMonitorStatus?.DataCollectorStatusHistory?.at(-1)?.LastOpenMeteoForecastLastReportDate|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                    </label>
                </td>
            </tr>
        </table>

        <br />
    </div>
</div>
