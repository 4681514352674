/// <reference path="../../node_modules/@types/jqueryui/index.d.ts" />
/// <reference path="../../node_modules/@types/jquery.ui.datetimepicker/index.d.ts"/>
import { environment } from './environments/environment';
import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AgGridModule } from 'ag-grid-angular';
import {
    SwxModule,
    GridModule,
    ExportApi,
    AuthModule,
    TranslatePipe,
    ChangesModule, 
    AuthInterceptorService
} from 'swx.front-end-lib';
import { TicketTrackingSystemService, TicketTrackingCommentDialogComponent } from './shared/TicketTrackingSystem.service';
import { ApiService } from './shared/Api.service';
import { AuthGuard } from './shared/AuthGuard.service';
import { HasPermissionPipe, HasPermissionService } from './shared/HasPermission.pipe';
import { AppComponent } from './app.component';
import { RawDataDialogComponent } from './shared/RawDataDialog.component';
import { LoginComponent } from './login/Login.component';
import { Saml2ErrorComponent } from './login/Saml2Error.component';
import { MenuComponent } from './menu/Menu.component';
import { UserDetailComponent } from './user/UserDetail.component';
import { UserListComponent } from './user/UserList.component';
import { ChangeGridComponent, DiffCellRendererComponent } from './change/ChangeGrid.component';
import { LoginHistoryComponent } from './loginHistory/LoginHistory.component';
import { DashboardComponent } from './dashboard/Dashboard.component';
import { DashboardServiceComponent } from './dashboard/DashboardService.component';
import { ServiceStatusComponent } from './serviceStatus/ServiceStatus.component';
import { ServiceMonitoringProfileDetailComponent } from "./serviceMonitoringProfile/ServiceMonitoringProfileDetail.component";
import { ServiceMonitoringProfileListComponent } from "./serviceMonitoringProfile/ServiceMonitoringProfileList.component";
import { ServiceMonitoringConditionListComponent } from "./serviceMonitoringProfile/ServiceMonitoringConditionList.component";
import { ServiceMonitoringAlertListComponent } from "./serviceMonitoringAlert/ServiceMonitoringAlertList.component";

@NgModule({
    declarations: [
        HasPermissionPipe,
        AppComponent,
        RawDataDialogComponent,
        MenuComponent,
        LoginComponent,
        UserDetailComponent,
        UserListComponent,
        ChangeGridComponent,
        Saml2ErrorComponent,
        DiffCellRendererComponent,
        TicketTrackingCommentDialogComponent,
        LoginHistoryComponent,
        DashboardComponent,
        DashboardServiceComponent,
        ServiceStatusComponent,
        ServiceMonitoringProfileDetailComponent,
        ServiceMonitoringProfileListComponent,
        ServiceMonitoringConditionListComponent,
        ServiceMonitoringAlertListComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [BrowserModule,
        RouterModule.forRoot([
            { path: 'login', component: LoginComponent },
            { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
            { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
            { path: 'changes', component: ChangeGridComponent, canActivate: [AuthGuard] },
            { path: 'saml2Error', component: Saml2ErrorComponent },
            { path: 'loginHistory', component: LoginHistoryComponent, canActivate: [AuthGuard] },
            { path: 'serviceStatus', component: ServiceStatusComponent, canActivate: [AuthGuard] },
            { path: 'serviceMonitoringAlerts', component: ServiceMonitoringAlertListComponent, canActivate: [AuthGuard] },
            { path: 'serviceMonitoringProfiles/:id', component: ServiceMonitoringProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'serviceMonitoringProfiles', component: ServiceMonitoringProfileListComponent, canActivate: [AuthGuard] },
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
            { path: '**', component: DashboardComponent, canActivate: [AuthGuard] },
        ]),
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        GridModule,
        AgGridModule,
        SwxModule.forRoot(environment),
        AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        }),
        ChangesModule.forRoot(environment),
    ], providers: [
        DecimalPipe,
        HasPermissionService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        TicketTrackingSystemService,
        TranslatePipe,
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
