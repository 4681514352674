import { Component, Input } from "@angular/core";

@Component({
    selector: 'dashboard-service',
    templateUrl: './DashboardService.component.html',
})
export class DashboardServiceComponent {
    @Input() name;
    @Input() serviceStatusHistory;
    Math = Math;
}
