import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import {ServiceMonitoringConditionListComponent} from "./ServiceMonitoringConditionList.component";
import {OrderByPipe, Base64UploadAdapterPlugin} from "swx.front-end-lib";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    templateUrl: 'ServiceMonitoringProfileDetail.component.html',
    styleUrls: ['ServiceMonitoringProfileDetail.component.scss'],
})
export class ServiceMonitoringProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    @ViewChild('list', { static: true }) list: ServiceMonitoringConditionListComponent;
    tab;
    serviceMonitoringActionTypes = this.api.ServiceMonitoringActionType.query();
    emailFroms = this.api.EmailFrom.query();
    Editor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        public orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.ServiceMonitoringProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.ServiceMonitoringConditions) {
                    this.item.ServiceMonitoringConditions.forEach(condition => {
                        delete condition.ServiceMonitoringProfileId;

                        var newId = ServiceMonitoringConditionListComponent.uuidv4();
                        this.item.ServiceMonitoringConditions.filter(c => c.ParentConditionId === condition.Id).forEach(child => {
                            child.ParentConditionId = newId;
                        });

                        condition.Id = newId;
                    });
                }

                if (this.item.ServiceMonitoringActions) {
                    this.item.ServiceMonitoringActions.forEach(related => {
                        delete related.ServiceMonitoringProfileId;
                        delete related.Id;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.ServiceMonitoringProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.ServiceMonitoringProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.item.ServiceMonitoringConditions = this.item.ServiceMonitoringConditions || [];
                this.item.ServiceMonitoringActions = this.item.ServiceMonitoringActions || [];
                this.item.ServiceMonitoringConditions = this.orderByPipe.transform(this.item.ServiceMonitoringConditions, 'Order');
            });
        }
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['ServiceMonitoringProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addAction() {
        this.item.ServiceMonitoringActions = this.item.ServiceMonitoringActions || [];
        var maxOrder = Math.max.apply(null, this.item.ServiceMonitoringActions
            .map(h => h.Order));
        this.item.ServiceMonitoringActions.push({
            Order: Math.max(1, maxOrder + 1),
        });
        this.ngForm.form.markAsDirty();
    }

    removeAction(action) {
        this.item.ServiceMonitoringActions.splice(this.item.ServiceMonitoringActions.indexOf(action), 1);
        this.ngForm.form.markAsDirty();
    }
}
